/* eslint-disable consistent-return, no-underscore-dangle */
import { Platform } from 'react-native';
import * as FileSystem from 'expo-file-system';
import * as Sharing from 'expo-sharing';
import * as Linking from 'expo-linking';
import * as WebBrowser from 'expo-web-browser';

import { base64ToArrayBuffer, triggerDownloadBlob } from '@dmi/shared/utils/globalHelpers';

import Bugsnag from '../../bugsnag';

export const isAndroid = () => Platform.OS === 'android';

export const isIPhone = () => Platform.OS === 'ios';

export const saveAttachmentToFile = async ({
  dispatchSetFile,
  dispatchToggleDrawer,
  fileContent,
  fileName,
  fileType,
}) => {
  try {
    let fileTypeToUse = 'application/pdf';
    if (fileType) {
      fileTypeToUse = fileType;
    }

    if (Platform.OS === 'web') {
      const bytes = base64ToArrayBuffer(fileContent);
      const blob = new Blob([bytes], { type: fileTypeToUse });
      const fileUrl = URL.createObjectURL(blob);
      const newWindow = window.open(fileUrl);
      if (!newWindow) {
        throw new Error('popupBlocked');
      }
    } else {
      const fileDirectory = `${FileSystem.documentDirectory}${fileName.replace(/\s/g, '')}`;
      await FileSystem.writeAsStringAsync(
        fileDirectory,
        fileContent,
        { encoding: FileSystem.EncodingType.Base64 },
      );
      if (isAndroid()) {
        const permissions = await FileSystem
          .StorageAccessFramework.requestDirectoryPermissionsAsync();
        await FileSystem.StorageAccessFramework.createFileAsync(
          permissions.directoryUri,
          fileName,
          fileTypeToUse,
        )
          .then(async (uri) => {
            await FileSystem.writeAsStringAsync(
              uri, fileContent,
              { encoding: FileSystem.EncodingType.Base64 },
            );
          });
      }
      if (isIPhone()) {
        dispatchSetFile({ fileName, uri: fileDirectory });
        dispatchToggleDrawer(true);
      }
    }
  } catch (error) {
    return error;
  }
};

export class OidcSingleLogout {
  constructor(url) {
    this.linkingSubscription = null;
    this.logoutUrl = url;
  }

  _handleRedirect() {
    if (Platform.OS === 'ios') {
      WebBrowser.dismissBrowser();
    }
    this.linkingSubscription.remove();
  }

  async initiate() {
    this.linkingSubscription = Linking.addEventListener('url', this._handleRedirect);
    await WebBrowser.openBrowserAsync(this.logoutUrl);
  }
}

export const saveCSVToFile = async ({
  csvString,
  fileName,
}) => {
  try {
    if (Platform.OS === 'web') {
      const blob = new Blob([csvString], { type: 'text/csv' });
      triggerDownloadBlob(blob, fileName);
    } else {
      const fileDirectory = `${FileSystem.documentDirectory}${fileName}`;
      if (isAndroid()) {
        const permissions = await FileSystem
          .StorageAccessFramework.requestDirectoryPermissionsAsync(
            'content://com.android.externalstorage.documents/tree/primary%3ADownload',
          );
        try {
          await FileSystem.StorageAccessFramework.createFileAsync(
            permissions.directoryUri,
            fileName,
            'text/csv',
          )
            .then(async (uri) => {
              await FileSystem.writeAsStringAsync(
                uri,
                csvString,
                { encoding: FileSystem.EncodingType.UTF8 },
              );
            });
        } catch {
          await Sharing.shareAsync(
            fileDirectory,
            {
              mimeType: 'text/csv',
              UTI: 'public.comma-separated-values-text',
            },
          );
        }
      }
      if (isIPhone()) {
        await FileSystem.writeAsStringAsync(fileDirectory, csvString);
        await Sharing.shareAsync(
          fileDirectory,
          { UTI: 'public.comma-separated-values-text' },
        );
      }
    }
  } catch (error) {
    const { message } = error;
    const iosDoubleTapErrorMessage = 'Another item is being shared';
    const androidDoubleTapErrorMessage = 'Another share request is being processed now';
    const isDoubleTapErrorMessage =
      message.startsWith(iosDoubleTapErrorMessage) ||
      message.startsWith(androidDoubleTapErrorMessage);
    /**
     * If user spams the download button, Sharing.shareAsync will reject on the subsequent attempts,
     * though the first call will still work/complete. This leads to uselss cluttering in the
     * Bugsnag logs. So we ignore that particular error here. (Any other errors are rare/unexpected
     * to the point that we don't even have a UI banner error for them.)
     */
    if (!isDoubleTapErrorMessage) {
      Bugsnag.notify(error);
    }
  }
};
