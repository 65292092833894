/* eslint-disable max-len */
import { INTL_IDS } from './messages';

export const ADD_DISMISSED_BANNER = 'app/Main/ADD_DISMISSED_BANNERS';
export const ADD_DISMISSED_DOC_UPLOAD_BANNER = 'app/Main/ADD_DISMISSED_DOC_UPLOAD_BANNER';

export const BIOMETRIC_LOGIN_REQUEST = 'app/Main/BIOMETRIC_LOGIN_REQUEST';
export const BIOMETRIC_LOGIN_REQUEST_FAILURE = 'app/Main/BIOMETRIC_LOGIN_REQUEST_FAILURE';

export const CANCEL_BIOMETRIC_AUTHENTICATION = 'app/Main/CANCEL_BIOMETRIC_AUTHENTICATION';

export const CANCEL_CHANGE_SELECTED_LOAN = 'app/Main/CANCEL_CHANGE_SELECTED_LOAN';
export const CANCEL_CHANGE_SELECTED_LOAN_SUCCESS = 'app/Main/CANCEL_CHANGE_SELECTED_LOAN_SUCCESS';

export const CANCEL_LOGIN = 'app/Main/CANCEL_LOGIN';

export const CHANGE_SELECTED_LOAN = 'app/Main/CHANGE_SELECTED_LOAN';
export const CHANGE_SELECTED_LOAN_FAILURE = 'app/Main/CHANGE_SELECTED_LOAN_FAILURE';
export const CHANGE_SELECTED_LOAN_SUCCESS = 'app/Main/CHANGE_SELECTED_LOAN_SUCCESS';

export const CLOSE_MODAL = 'app/Main/CLOSE_MODAL';

export const DISABLE_INSTANT_POST_LOGIN_MODAL = 'app/Main/DISABLE_INSTANT_POST_LOGIN_MODAL';
export const DISABLE_MODAL = 'app/Main/DISABLE_MODAL';

export const DISMISS_UPDATE_MODAL = 'app/Main/DISMISS_UPDATE_MODAL';

export const DISPLAY_CHATBOT_ERROR_BANNER = 'app/Main/DISPLAY_CHATBOT_ERROR_BANNER';

export const FETCH_OIDC_CONFIG = 'app/Main/FETCH_OIDC_CONFIG';
export const FETCH_OIDC_CONFIG_FAILURE = 'app/Main/FETCH_OIDC_CONFIG_FAILURE';

export const FETCH_RESPA_LETTER = 'app/Main/FETCH_RESPA_LETTER';
export const FETCH_RESPA_LETTER_FAILURE = 'app/Main/FETCH_RESPA_LETTER_FAILURE';

export const FETCH_TRANSFER_STATUS = 'app/Main/FETCH_TRANSFER_STATUS';
export const FETCH_TRANSFER_STATUS_FAILURE = 'app/Main/FETCH_TRANSFER_STATUS_FAILURE';
export const FETCH_TRANSFER_STATUS_SUCCESS = 'app/Main/FETCH_TRANSFER_STATUS_SUCCESS';

export const INITIALIZE_OIDC_LOGIN = 'app/Main/INITIALIZE_OIDC_LOGIN';
export const INITIALIZE_OIDC_LOGIN_CANCELLED = 'app/Main/INITIALIZE_OIDC_LOGIN_CANCELLED';
export const INITIALIZE_OIDC_LOGIN_FAILURE = 'app/Main/INITIALIZE_OIDC_LOGIN_FAILURE';

export const INITIALIZE_REQUEST = 'app/Main/INITIALIZE_REQUEST';
export const INITIALIZE_REQUEST_FAILURE = 'app/Main/INITIALIZE_REQUEST_FAILURE';
export const INITIALIZE_REQUEST_SUCCESS = 'app/Main/INITIALIZE_REQUEST_SUCCESS';

export const LINK_NATIVE_CLIENT = 'app/Main/LINK_NATIVE_CLIENT';
export const LINK_NATIVE_CLIENT_FAILURE = 'app/Main/LINK_NATIVE_CLIENT_FAILURE';
export const LINK_NATIVE_CLIENT_SUCCESS = 'app/Main/LINK_NATIVE_CLIENT_SUCCESS';

export const LOGIN_REQUEST = 'app/Main/LOGIN_REQUEST';
export const LOGIN_REQUEST_CANCELLED = 'app/Main/LOGIN_REQUEST_CANCELLED';
export const LOGIN_REQUEST_FAILURE = 'app/Main/LOGIN_REQUEST_FAILURE';
export const LOGIN_REQUEST_SUCCESS = 'app/Main/LOGIN_REQUEST_SUCCESS';

export const LOGOUT_REQUEST = 'app/Main/LOGOUT_REQUEST';
export const LOGOUT_REQUEST_SUCCESS = 'app/Main/LOGOUT_REQUEST_SUCCESS';

export const MFA_OPTION_REQUEST = 'app/Main/MFA_OPTION_REQUEST';
export const MFA_OPTION_REQUEST_CANCEL = 'app/Main/MFA_OPTION_REQUEST_CANCEL';
export const MFA_OPTION_REQUEST_FAILURE = 'app/Main/MFA_OPTION_REQUEST_FAILURE';
export const MFA_OPTION_REQUEST_SUCCESS = 'app/Main/MFA_OPTION_REQUEST_SUCCESS';

export const NATIVE_CLIENTS_REQUEST_FAILURE = 'app/Main/NATIVE_CLIENTS_REQUEST_FAILURE';

export const OAUTH_LOGIN = 'app/Main/OAUTH_LOGIN';
export const OAUTH_LOGIN_FAILURE = 'app/Main/OAUTH_LOGIN_FAILURE';
export const OAUTH_LOGIN_SUCCESS = 'app/Main/OAUTH_LOGIN_SUCCESS';

export const OPEN_MODAL = 'app/Main/OPEN_MODAL';

export const OPEN_PIN_DRAWER = 'app/Main/OPEN_PIN_DRAWER';
export const OPEN_PIN_DRAWER_FAILURE = 'app/Main/OPEN_PIN_DRAWER_FAILURE';

export const PIN_LOGIN_REQUEST = 'app/Main/PIN_LOGIN_REQUEST';
export const PIN_LOGIN_REQUEST_FAILURE = 'app/Main/PIN_LOGIN_REQUEST_FAILURE';

export const PUT_LANGUAGE_PREFERENCE = 'app/Main/PUT_LANGUAGE_PREFERENCE';
export const PUT_LANGUAGE_PREFERENCE_FAILURE = 'app/Main/PUT_LANGUAGE_PREFERENCE_FAILURE';
export const PUT_LANGUAGE_PREFERENCE_SUCCESS = 'app/Main/PUT_LANGUAGE_PREFERENCE_SUCCESS';

export const PUT_PAPERLESS_SETTING = 'app/Main/PUT_PAPERLESS_SETTING';
export const PUT_PAPERLESS_SETTING_FAILURE = 'app/Main/PUT_PAPERLESS_SETTING_FAILURE';
export const PUT_PAPERLESS_SETTING_SUCCESS = 'app/Main/PUT_PAPERLESS_SETTING_SUCCESS';

export const RECONSENT_REQUEST = 'app/Main/RECONSENT_REQUEST';
export const RECONSENT_REQUEST_FAILURE = 'app/Main/RECONSENT_REQUEST_FAILURE';
export const RECONSENT_REQUEST_SUCCESS = 'app/Main/RECONSENT_REQUEST_SUCCESS';

export const REFRESH_MONITOR = 'app/Main/REFRESH_MONITOR';
export const REFRESH_TOKEN = 'app/Main/REFRESH_TOKEN';
export const REFRESH_TOKEN_FAILURE = 'app/Main/REFRESH_TOKEN_FAILURE';

export const RESET_MAIN_ERROR = 'app/Main/RESET_MAIN_ERROR';
export const RESET_MAIN_SUCCESS = 'app/Main/RESET_MAIN_SUCCESS';
export const RESET_SSO_MIGRATION = 'app/Main/RESET_SSO_MIGRATION';
export const RESET_STATUS = 'app/Main/RESET_STATUS';
export const RESET_SWITCH_LOAN_STATE = 'app/Main/RESET_SWITCH_LOAN_STATE';
export const RESET_USER_STATE = 'app/Main/RESET_USER_STATE';

export const SET_CHATBOT_AUTH_TOKEN = 'app/Main/SET_CHATBOT_AUTH_TOKEN';
export const SET_CHATBOT_WINDOW_STATUS = 'app/Main/SET_CHATBOT_WINDOW_STATUS';

export const SET_INSTANT_POST_LOGIN_MODAL_STEP = 'app/Main/SET_INSTANT_POST_LOGIN_MODAL_STEP';

export const SET_IS_CHANGE_SELECTED_LOAN_IN_PROGRESS = 'app/Main/SET_IS_CHANGE_SELECTED_LOAN_IN_PROGRESS';

export const SET_IS_SSL_AUTH = 'app/Main/SET_IS_SSL_AUTH';

export const SET_LANGUAGE_PICKER = 'app/Main/SET_LANGUAGE_PICKER';

export const SET_RESPA_FILE = 'app/Main/SET_RESPA_FILE';

export const SET_SELECTED_LANGUAGE = 'app/Main/SET_SELECTED_LANGUAGE';

export const SET_SHOULD_RENDER_SPLASH_SCREEN = 'app/Main/SET_SHOULD_RENDER_SPLASH_SCREEN';

export const SET_SSO_MIGRATION = 'app/Main/SET_SSO_MIGRATION';

export const TOGGLE_RESPA_DRAWER = 'app/Main/TOGGLE_RESPA_DRAWER';

export const TOTP_CANCEL_REQUEST = 'app/Main/TOTP_CANCEL_REQUEST';
export const TOTP_REQUEST = 'app/Main/TOTP_REQUEST';
export const TOTP_REQUEST_FAILURE = 'app/Main/TOTP_REQUEST_FAILURE';
export const TOTP_REQUEST_SUCCESS = 'app/Main/TOTP_REQUEST_SUCCESS';

export const UPDATE_DISMISSED_BANNERS = 'app/Main/UPDATE_DISMISSED_BANNERS';
export const UPDATE_DISMISSED_DOC_UPLOAD_BANNERS = 'app/Main/UPDATE_DISMISSED_DOC_UPLOAD_BANNERS';
export const UPDATE_IS_LOGGED_IN = 'app/Main/UPDATE_IS_LOGGED_IN';
export const UPDATE_LOANS = 'app/Main/UPDATE_LOANS';
export const UPDATE_PRIMARY_LOAN = 'app/Main/UPDATE_PRIMARY_LOAN';
export const UPDATE_REFRESH_TOKEN = 'app/Main/UPDATE_REFRESH_TOKEN';
export const UPDATE_SHOULD_BIOMETRICS_OPEN_AUTOMATICALLY = 'app/Main/UPDATE_SHOULD_BIOMETRICS_OPEN_AUTOMATICALLY';
export const UPDATE_SSO_MIGRATION_URL = 'app/Main/UPDATE_SSO_MIGRATION_URL';
export const UPDATE_USER_LOANS_DATA = 'app/Main/UPDATE_USER_LOANS_DATA';
export const UPDATE_USER_PROFILE = 'app/Main/UPDATE_USER_PROFILE';

export const COMPLETE_CLAIM_LEVEL = 'complete';
export const RECONSENT_CLAIM_LEVEL = 'consent_required';
export const MFA_SELECTION_CLAIM_LEVEL = 'mfa_selection';
export const TOTP_CLAIM_LEVEL = 'basic';

// AuthToken Status Constants
export const AUTH_FULL = 'AUTH_FULL';
export const AUTH_INCOMPLETE = 'AUTH_INCOMPLETE';
export const AUTH_RECONSENT = 'AUTH_RECONSENT';
export const AUTH_TOTP = 'AUTH_TOTP';
export const AUTH_MFA_SELECTION = 'AUTH_MFA_SELECTION';

// Biometrics/Pin Constants
export const ACCOUNT_LOCKOUT_EXPIRATION_TIME = 'ACCOUNT_LOCKOUT_EXPIRATION_TIME';
export const INVALID_BIOMETRICS_ENROLLMENT_KEYCHAIN = 'invalidBiometricsEnrollmentKeychain';
export const INVALID_PIN_KEYCHAIN = 'invalidPinKeychain';
export const INVALID_REFRESH_TOKEN_KEYCHAIN = 'invalidRefreshTokenKeychain';
export const INVALID_USERNAME_KEYCHAIN = 'invalidUsernameKeychain';
export const PIN_ATTEMPT_COUNT = 'PIN_ATTEMPT_COUNT';
export const PIN_LOCKOUT_EXPIRATION_TIME = 'PIN_LOCKOUT_EXPIRATION_TIME';
export const TOKEN_EXPIRATION_ERROR_MSG = 'Please log in with your username and password to enable biometric and/or PIN authentication.';

export const LINK_CLIENT_ATTEMPT_COUNT = 'LINK_CLIENT_ATTEMPT_COUNT';
export const LINK_CLIENT_EXPIRATION_TIME = 'LINK_CLIENT_EXPIRATION_TIME';
export const LINK_CLIENT_LOCKOUT_MESSAGE = 'You have been blocked from linking an account due to several unsuccessful attempts. Please try again later.';

// Client Info Constants
export const GENERIC_CUSTOMER_SERVICE_PHONE_NUMBER = '1-800-669-4268';

/* eslint-disable quote-props */
export const ERROR_MESSAGES = {
  '1000': 'The username or password is incorrect.',
  '1001': 'The user account is locked, try again later',
  '1003': 'Passcode expired. Please return to the login screen and try again.',
  '1004': 'The device or browser used to generate your passcode does not match the one you are currently using. Please click Cancel and return to the login screen if you wish to generate a passcode for this device.',
  '1005': 'No loans available for this user. Please try again.',
  '1006': 'An email has been sent to the email address on file. Please click on the link within that email to complete the registration process.',
  '1007': 'User information not available',
  '1008': 'All consents and disclosures need to be agreed to',
  '1014': 'SSO Authentication Only',
};
/* eslint-enable quote-props */

export const CHATBOT_ERROR_BANNER_MESSAGE = 'Unable to connect to the chatbot. Please try again later.';
export const CHATBOT_QUERY_PARAM_MURABAHA = 'isAnyLoanMurabaha';

export const INACTIVITY_MODAL_TITLE = 'Your session is about to expire';
export const INACTIVITY_MODAL_TEXT = "For your security, we'll sign you out in approximately 1 minute if there is no\nadditional activity.";

export const CHANGE_SELECTED_LOAN_ERROR_MSG = 'Unable to switch loans.\nPlease try again later.';
export const LOGOUT_SUCCESS_MESSAGE = 'You have successfully logged out.';
export const LOGOUT_INACTIVITY_MESSAGE = 'You have been logged out due to session inactivity. Please log in again.';
export const LOGOUT_SESSION_EXPIRATION_MESSAGE = 'Your current session has expired. Please log in again.';
export const TOTP_VALIDATION_ERROR_MESSAGE = 'The passcode should be six digits long.';

export const NATIVE_CLIENT_RESET_ERROR_MESSAGE = 'There was an issue retrieving your linked accounts from this device. This information has been autmomatically reset and you will need to link your accounts again within the application. Please restart the application to start that process.';
export const NATIVE_CLIENT_ERROR_MESSAGE = 'There was an issue retrieving your linked accounts from this device. Please restart the application and try again.';

export const REDIRECT_NAV_DICT = {
  desktop: {
    AUTH_MFA_SELECTION: '/login/mfa',
    AUTH_RECONSENT: '/login/consent',
    AUTH_TOTP: '/login/totp',
    logout: '/login',
  },
  mobile: {
    AUTH_MFA_SELECTION: 'Mfa',
    AUTH_RECONSENT: 'Reconsent',
    AUTH_TOTP: 'Totp',
  },
};

// DocUploadStatus constants
export const DOC_UPLOAD_STATUS_TITLE_DICT = {
  Failed: INTL_IDS.DOCUPLOAD_STATUS_TITLE_FAILED,
  InProcess: INTL_IDS.DOCUPLOAD_STATUS_TITLE_INPROCESS,
  Processed: INTL_IDS.DOCUPLOAD_STATUS_TITLE_PROCESSED,
  Uploaded: INTL_IDS.DOCUPLOAD_STATUS_TITLE_UPLOADED,
};

export const MOST_RECENT_FAILED_UPLOAD = 'most-recent-failed-upload';

// SPLIT.IO SPLITS
export const SPLIT_COVIDBANNER = 'Covid_Banner';
export const SPLIT_COVIDBANNER_PRELOGIN = 'Covid_Banner_Prelogin';
export const SPLIT_1098BANNER_PRELOGIN = 'Interest_Statement_1098_Banner_Prelogin';
export const SPLIT_HURRICANEHELENEBANNER = 'Hurricane_Helene_Banner';
export const SPLIT_HURRICANEIANBANNER = 'Hurricane_Ian_Banner';
export const SPLIT_LA_WILDFIRE_BANNER = 'LA_Wildfire_Banner';

export const SPLIT_JIRASECUREMESSAGING = 'Jira_Secure_Messaging';

export const SPLIT_OUTAGE_BANNER = 'Outage_Banner';

export const SPLIT_SSO_PRETRANSFER = 'SSO_Pretransfer';
export const SPLIT_SSO_PRETRANSFER_PRELOGIN = 'SSO_Pretransfer_Prelogin';

export const SSO_PRETRANSFER_MODAL_TITLE = 'New website and mobile app coming soon';
export const SSO_PRETRANSFER_MODAL_TEXT = "We're making things better for you. You will soon access your loan information using our brand new website and mobile app. Stay tuned for updates.";

export const SPLIT_ZENDESK_CHATBOT = 'Zendesk_Chatbot';

// MODAL VIEWS CONTENT
export const MODAL_NYC_LANGUAGE_PREFERENCE_CONFIRMATION_CONTENT = {
  heading: 'Thank you for your participation!',
  text: 'Your response has been successfully recorded in accordance with New York laws.',
};

export const MODAL_NYC_LANGUAGE_PREFERENCE_ERROR_CONTENT = {
  heading: 'We were unable to record your response due to an error',
  text: 'We will try again the next time you log in.',
};

export const MODAL_NYC_LANGUAGE_PREFERENCE_MAIN_CONTENT = {
  heading: 'New York City rules require that we request and record your language preference',
  text: 'Please select your language preference:',
};

export const MODAL_PAPERLESS_BILLING_MAIN_CONTENT = {
  heading: 'Enroll in paperless billing',
  items: [
    {
      subHeading: 'Convenient',
      text: 'Access statements from virtually anywhere.',
    },
    {
      subHeading: 'Safe & Secure',
      text: 'Get instantly notified when a new statement is available online.',
    },
    {
      subHeading: 'Earth-friendly',
      text: 'Reduce paper waste and clutter.',
    },
  ],
};

export const MODAL_PAPERLESS_BILLING_CONFIRMATION_CONTENT = {
  heading: 'Thank you for enrolling',
  label: 'Continue to Dashboard',
  text: 'This is a confirmation that you have requested paperless service for your statements. Your request has been received and is in process. You will receive an alert when paper statements have been disabled and you can view your e-statements online.',
};

export const MODAL_SSO_MIGRATION_CONTENT = {
  app: {
    confirmLabel: 'Get Started',
    credentialsHeader: 'Linking your account to the app is quick and easy. Here\'s what you will need:',
    formImageHeader: 'Accessing our new site is easy',
    formImageParagraph: 'This is what you\'ll see when you get there.',
    title: 'We\'ve moved to a new mobile application!',
  },
  web: {
    confirmLabel: 'Go to New Site',
    credentialsHeader: 'Here\'s what you need to log in',
    formImageHeader: 'Accessing our new site is easy',
    formImageParagraph: 'This is what you\'ll see when you get there.',
    title: 'We\'ve moved to a new website!',
  },
};

export const STATE_ABBR_DICTIONARY = {
  CO: 'Colorado',
  CT: 'Connecticut',
  HI: 'Hawaii',
  IA: 'Iowa',
  IL: 'Illinois',
  NY: 'New York',
  WA: 'Washington',
};

// FOOTER
export const CHAPTER_SEVEN_BANKRUPTCY_DICT = {
  header: 'Chapter 7 Bankruptcy Discharge Notice',
  text: 'The information in this website is provided for informational purposes only and does not constitute an attempt to collect a debt in violation of the bankruptcy discharge injunction. Further, your ability to access and use this website does not in any way constitute a demand or solicitation for payment, and any online payment made by you through this website is done at your sole discretion.',
};

// RESPA LETTER
export const FETCH_RESPA_LETTER_FAILURE_MSG = 'We are unable to retrieve your RESPA letter at this time. Please try again later.';
export const LETTER_BLOCKED_MESSAGE = 'You will need to enable pop-ups in your browser for this site in order to view this content.';

// TRANSFER STATUS
export const TRANSFER_STATUS_UNAVAILABLE = 'Servicing transfer information is not available for this loan';

export const POST_TRANSFER_BANNER_TEXT = {
  mobileText: 'Access to most online features has been discontinued as part of the transfer.',
  text: 'Access to most online features has been discontinued as part of the service transfer. We apologize for any inconvenience. For additional information, call {{phoneNumber}}.',
  title: 'Your mortgage has been transferred',
};

export const POST_TRANSFER_RESPA_BANNER_TEXT = {
  mobileText: 'Access to most online features has been discontinued as part of the transfer.',
  text: 'Access to most online features has been discontinued as part of the service transfer. We apologize for any inconvenience. For additional information, please click on the link.',
  title: 'Your mortgage has been transferred',
};

export const PRE_TRANSFER_BANNER_TEXT = {
  mobileText: 'Online access may be limited during the transition period. For additional information, call {{phoneNumber}}.',
  text: 'Online access may be limited during the transition period. We apologize for any inconvenience. For additional information, call {{phoneNumber}}.',
  title: 'Your mortgage is in the process of being transferred',
};

export const PRE_TRANSFER_RESPA_BANNER_TEXT = {
  mobileText: 'Online access may be limited during the transition period.',
  text: 'Online access may be limited during the transition period. We apologize for any inconvenience. For additional information, please click on the link.',
  title: 'Your mortgage is in the process of being transferred',
};

// UNAVAILABLE LOANS
export const UNAVAILABLE_LOANS_CONTENT = {
  Foreclosed: {
    heading: 'The loan you are trying to access is currently unavailable to be viewed through this website',
    illustration: 'errorPorchlight',
    text: 'If you have any questions, please contact the Customer Service Department at {{customerServicePhoneNumber}}.',
  },
  Inactive: {
    heading: 'We have upgraded to a new website',
    illustration: 'welcomeHome',
    linkLabel: 'Register here',
    text: 'If you have any questions, please contact the Customer Service Department at {{customerServicePhoneNumber}}.',
  },
  Processing: {
    heading: 'The loan you are trying to access is currently unavailable to be viewed through this website',
    illustration: 'errorPorchlight',
    text: 'If you have any questions, please contact the Customer Service Department at {{customerServicePhoneNumber}}.',
  },
};

export const UNAVAILABLE_LOANS_CONTENT_ERROR_TEXT = 'If you have any questions, please contact the Customer Service Department at {{customerServicePhoneNumber}}.';

export const DEFAULT_FONT = {
  name: 'Roboto',
  nameWithFallback: "'Roboto', sans-serif",
  stylesheet: 'https://fonts.googleapis.com/css?family=Roboto:300,400,400i,700',
};

export const DEFAULT_CLIENT_THEME_COLORS = {
  error: '#BE430D',
  footerBackground: '#FFFFFF',
  footerIconColor: '#000000',
  footerLinkText: '',
  footerText: '#282828',
  headerBackground: '#FFFFFF',
  linkText: '#310078',
  linkTextHover: '#250455',
  navigationMenuActive: '',
  primary: '#310078',
  primaryButtonHover: '#250455',
  primaryButtonText: '#FFFFFF',
  primaryButtonTextHover: '#FFFFFF',
  svgHighlight: '#DAD3E4',
  useDefaultMobileButtonOnPress: false,
};

export const DEFAULT_DESKTOP_THEME_COLORS = {
  accentGray: '#50555C',
  accentGreen: '#00DB7D',
  accentRed: '#BE430D',
  accentSilver: '#F5F6F8',
  backgroundLightGray: '#FAFAFA',
  buttonGray: '#DEE2E6',
  disabled: '#DEE2E6',
  disabledText: '#AAAAAA',
  dmiWhite: '#FFFFFF',
  error: '#BE430D',
  hoverGray: '#CED4DB',
  primary: '#310078',
  primaryAccent: '#F5F6F8',
  primaryBackground: '#FFFFFF',
  primaryButtonHover: '#250455',
  primaryCarbon: '#282828',
  primaryGray: '#BDC5CD',
  primaryText: '#282828',
  secondary: '#BDC5CD',
  secondaryAccent: '#50555C',
  secondaryBackground: '#FAFAFA',
  secondaryButton: '#FFFFFF',
  secondaryFont: '#FFFFFF',
  secondaryHover: '#CED4DB',
  success: '#00DB7D',
  tertiary: '#FFFFFF',
};

export const DEFAULT_MOBILE_THEME_COLORS = {
  accentGray: '#50555C',
  accentGreen: '#00DB7D',
  accentRed: '#D94C0F',
  accentSilver: '#F5F6F8',
  buttonGray: '#DEE2E6',
  disabledText: '#AAAAAA',
  dmiWhite: '#FFFFFF',
  hoverGray: '#CED4DB',
  iconStatic: '#282828',
  primary: '#310078',
  primaryButton: '#310078',
  primaryButtonHover: '#250455',
  primaryCarbon: '#282828',
  primaryGray: '#BDC5CD',
};

export const WHATS_NEW_TILES_DICTIONARY = {
  amortization: {
    ariaLabel: 'Payoff your loan early, explore calculators',
    desktopLink: '/calculators',
    icon: 'calculatorMT',
    linkText: INTL_IDS.TILES_AMORTIZATION_LINKTEXT,
    screen: 'Calculators',
    text: INTL_IDS.TILES_AMORTIZATION_TEXT,
    title: INTL_IDS.TILES_AMORTIZATION_TITLE,
  },
  autopay: {
    ariaLabel: 'Enroll in Autopay today',
    desktopLink: '/payments/autopay',
    icon: 'autopayMoney',
    linkText: INTL_IDS.TILES_AUTOPAY_LINKTEXT,
    nestedScreen: 'AutopayPreConfirmation',
    screen: 'Payments',
    text: INTL_IDS.TILES_AUTOPAY_TEXT,
    title: INTL_IDS.TILES_AUTOPAY_TITLE,
  },
  documents: {
    ariaLabel: 'View documents',
    desktopLink: '/documents',
    icon: 'documentsStatements',
    linkText: INTL_IDS.TILES_DOCUMENTS_LINKTEXT,
    screen: 'Documents',
    text: INTL_IDS.TILES_DOCUMENTS_TEXT,
    title: INTL_IDS.TILES_DOCUMENTS_TITLE,
  },
  learningCenter: {
    ariaLabel: 'Learn more about homeownership',
    desktopLink: '/help/learningcenter',
    icon: 'helpLearningCenter',
    linkText: INTL_IDS.TILES_LEARNINGCENTER_LINKTEXT,
    nestedScreen: 'LearningCenter',
    screen: 'Help',
    text: INTL_IDS.TILES_LEARNINGCENTER_TEXT,
    title: INTL_IDS.TILES_LEARNINGCENTER_TITLE,
  },
  paperlessBilling: {
    ariaLabel: 'Enroll in paperless billing',
    desktopLink: '/settings/communications/paperless',
    icon: 'paperlessBillingRecycle',
    linkText: INTL_IDS.TILES_PAPERLESSBILLING_LINKTEXT,
    screen: 'Paperless',
    text: INTL_IDS.TILES_PAPERLESSBILLING_TEXT,
    title: INTL_IDS.TILES_PAPERLESSBILLING_TITLE,
  },
  settings: {
    ariaLabel: 'View settings',
    desktopLink: '/settings',
    icon: 'settingsThumbsUp',
    linkText: INTL_IDS.TILES_SETTINGS_LINKTEXT,
    screen: 'Settings',
    text: INTL_IDS.TILES_SETTINGS_TEXT,
    title: INTL_IDS.TILES_SETTINGS_TITLE,
  },
};

// PRELOGIN ALERT BANNER CONSTANTS
export const DISMISSIBLE_DISCLOSURE_BANNER = 'dismissibleDisclosure';
export const DISMISSIBLE_BANNER = 'dismissible';
export const NON_DISMISSIBLE_BANNER = 'nonDismissible';

export const PRELOGIN_ALERT_BANNER_PROPS = {
  [SPLIT_1098BANNER_PRELOGIN]: {
    bannerName: 'interestStatement-2025',
    bannerType: DISMISSIBLE_BANNER,
    dateAdded: new Date('2024-12-01T00:00:00Z'),
    heading: '1098 Interest Statement',
    messageText: 'Your 1098 interest statement for 2024 will be mailed and available online by January 31st. Please keep in mind that we cannot reproduce and/or duplicate 1098 statements until February 17, 2025.',
  },
  [SPLIT_COVIDBANNER_PRELOGIN]: {
    ariaLabel: 'Coronavirus (Covid-19) disclosure',
    bannerName: 'covid',
    bannerType: DISMISSIBLE_DISCLOSURE_BANNER,
    dateAdded: new Date('2020-04-01T00:00:00Z'),
    disclosureName: 'hardshipAssistance',
    heading: 'Coronavirus Disease (Covid-19)',
    isUrgent: true,
    linkLabel: 'Learn more',
    linkPath: '/disclosuresandhotlines/hardshipassistance',
  },
  [SPLIT_HURRICANEHELENEBANNER]: {
    ariaLabel: 'Helene disclosure',
    bannerName: 'helene',
    bannerType: DISMISSIBLE_DISCLOSURE_BANNER,
    dateAdded: new Date('2024-10-01T00:00:00Z'),
    disclosureName: 'hurricaneHelene',
    heading: 'Hurricane Helene Disaster Assistance',
    isUrgent: true,
    linkLabel: 'Learn more',
    linkPath: '/disclosuresandhotlines/hurricanehelene',
  },
  [SPLIT_HURRICANEIANBANNER]: {
    ariaLabel: 'Disaster Assistance disclosure',
    bannerName: 'ian',
    bannerType: DISMISSIBLE_DISCLOSURE_BANNER,
    dateAdded: new Date('2021-04-05T00:00:00Z'),
    disclosureName: 'hurricaneIan',
    heading: 'Disaster Assistance',
    isUrgent: true,
    linkLabel: 'Learn more',
    linkPath: '/disclosuresandhotlines/hardshipassistance',
  },
  [SPLIT_LA_WILDFIRE_BANNER]: {
    bannerName: 'laWildfireAssistance-jan-2025',
    bannerType: DISMISSIBLE_BANNER,
    dateAdded: new Date('2025-01-14T00:00:00Z'),
    heading: 'LA Wildfire Assistance',
    isUrgent: true,
    messageText: 'If you have been impacted by the Los Angeles Wildfires, we are here to help. For payment assistance, please visit <extLinkBasic>loansolutioncenter.com</extLinkBasic>.',
  },
  [SPLIT_OUTAGE_BANNER]: {
    bannerName: 'outageBanner',
    bannerType: NON_DISMISSIBLE_BANNER,
  },
  [SPLIT_SSO_PRETRANSFER_PRELOGIN]: {
    bannerName: 'ssoPretransferBanner',
    bannerType: NON_DISMISSIBLE_BANNER,
    heading: 'Good things are coming soon',
    messageText: "We're improving the login experience for you. Stay tuned for more details.",
  },
};

export const CLIENT_FEATURES_SSO_HIDE_LOGOUT_BUTTON = {
  default: {
    native: [],
    web: [],
  },
};

// ASYNC STORAGE KEYS
export const STORAGE_KEY_LANGUAGE_PICKER_VALUE = 'languagePickerValue';
export const STORAGE_KEY_LOSS_DRAFT_READ_CLAIM_NUMBERS = 'lossDraftReadClaimNumbers';
export const STORAGE_KEY_NOTIFICATIONS_MODAL_AUTO_OPENED = 'notificationsModalAutoOpened';

// STORE URLS
export const NATIVE_APP_STORE_URLS = {
  android: 'http://play.google.com/store/apps/details?id=com.dovenmuehle&pli=1',
  ios: 'itms-apps://itunes.apple.com/us/app/your-mortgage-online/id1169497559',
};

// CHATBOT
export const CHATBOT_WINDOW_STATUS_CLOSED = 'closed';
export const CHATBOT_WINDOW_STATUS_ERROR = 'error';
export const CHATBOT_WINDOW_STATUS_OPEN = 'open';
