/**
 * Placeholder Bugsnag object with as basic of an interface as necessary so we can call methods
 * on it on native or web without it breaking. Just no-ops for now here.
 *
 * TODO: Eventually we can replace placeholder with actual Bugsnag instance if we want to track
 * bugs this way on mobile-web.
 */

const Bugsnag = { notify: () => {} };

export default Bugsnag;
