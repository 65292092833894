/**
 * Used by formatErrorMessages() in globalHelpers.js
 * See SS2 packages/silver/app/utils/constants.js for reference
 */

export const CASE_CONVERTER_EXCEPTIONS = {
  lastFourSsn: 'Last_Four_SSN',
  nsfFees: 'NSF_Fees',
  propertyZip: 'Property_ZIP',
};

export const FORMAT_INPUT_FIELDS = {
  accountNumberConfirm: 'confirm account number',
  bestMethods: 'best method to contact you',
  bestTimes: 'best time to contact you',
  body: 'compose message',
  delayDays: 'days delayed',
  fileInput: 'uploaded file',
  lastFourSsn: 'SSN (Last 4 digits) / TIN (9999 format)',
  nameOnAccount: 'name on bank account',
  propertyZip: 'zip code',
  scheduledDate: 'schedule a date',
  topicId: 'please select a topic',
  zip: 'zip code',
};

export const DEFAULT_ERROR_MSG = 'An unknown error has occurred. Please try again later.';
export const GENERAL_SERVER_ERROR_MSG = 'Uh oh. Something unexpected occurred.';

/// --------- AsyncStorage related constants --------- ///

export const DEVICE_TOKENS_HEADER_TITLE = 'X-Device-Tokens';
export const DEVICE_TOKENS_STORAGE_KEY = 'deviceTokens';

export const ANONYMOUS_SPLIT_ID_HEADER_TITLE = 'X-Anonymous-Split-Id';
export const ANONYMOUS_SPLIT_ID_STORAGE_KEY = 'anonymousSplitId';

/**
 * Constants representing names of session-related attributes.
 * Will either be stored within the Mobile.session Redux state (native build) or
 * will be defined as separate session cookies (web build)
 */
export const AUTH_TOKEN_SESSION_KEY = 'authToken';
export const CLIENT_CODE_SESSION_KEY = 'clientCode';
export const SELECTED_LOAN_SESSION_KEY = 'selectedLoan';

/**
 * Object of labels associated with our session-related headers.
 * - Each main property's key is meant to be the name/key used when storing it
 * - Each main property's values are the titles when attaching the item in a request or response
 * header.
 */
export const SESSION_HEADERS_STORAGE_DICTIONARY = {
  [AUTH_TOKEN_SESSION_KEY]: {
    requestHeaderTitle: 'Authorization',
    responseHeaderTitle: 'X-Auth-Token',
  },
  [CLIENT_CODE_SESSION_KEY]: {
    requestHeaderTitle: 'X-Client-Code',
    responseHeaderTitle: 'X-Client-Code',
  },
  [SELECTED_LOAN_SESSION_KEY]: {
    requestHeaderTitle: 'X-Selected-Loan',
    responseHeaderTitle: 'X-Selected-Loan',
  },
};

// amount of time progress button shows checkmark
export const CHECKMARK_TIME_UNTIL_TRANSITION = 300;

export const INFORMATION_NOT_AVAILABLE = 'Information not available';
// saga injector
export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

// Content-Types
export const CONTENT_TYPES = [
  'application/pdf',
  'image/gif',
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/tiff',
];

// whitelist domains for SEO indexing
export const PROD_HOSTNAMES = [
  'crosscountrymortgage.com',
  'yourmortgageonline.com',
];

// Payment - No Fee
export const MAKE_PAYMENT_NO_FEE = 'There is no fee to use either service';
export const REVIEW_PAYMENT_NO_FEE = 'There is no fee to use this service.';

export const LANGUAGE_ENGLISH = 'en';
export const LANGUAGE_ENGLISH_MURABAHA = 'en-murabaha';
export const LOCALE_ENGLISH_US = 'en-US';

// Language picker menu items
export const LANGUAGE_PICKER_OPTIONS = [
  {
    label: 'Default',
    value: '',
  },
  {
    label: 'English',
    value: LANGUAGE_ENGLISH,
  },
  {
    label: 'Murabaha',
    value: LANGUAGE_ENGLISH_MURABAHA,
  },
];
