/* eslint-disable max-len */
export const CHANGE_INPUT = 'app/Login/CHANGE_INPUT';
export const FOCUS_INPUT = 'app/Login/FOCUS_INPUT';

export const RESET_INPUTS = 'app/Login/RESET_INPUTS';

export const RESET_LOGIN = 'app/Login/RESET_LOGIN';
export const RESET_RECONSENT = 'app/Login/RESET_RECONSENT';
export const RESET_TOTP = 'app/Login/RESET_TOTP';

export const SET_IS_FIRST_PRE_AUTH_VISIT = 'app/Login/SET_IS_FIRST_PRE_AUTH_VISIT';

export const TOGGLE_DRAWER = 'app/Login/TOGGLE_DRAWER';

export const UPDATE_MFA_OPTIONS = 'app/Login/UPDATE_MFA_OPTIONS';

export const VALIDATION_ERROR = 'app/Login/VALIDATION_ERROR';

export const SSO_IDP_ERROR_MESSAGE = "Please close this browser and log in from your mortgage provider's website.";
export const SSO_LOGIN_ERROR_MESSAGE = 'An error occurred while verifying your information. Please close this browser tab and try again later. For further assistance, please contact your mortgage provider.';

export const ERROR_MESSAGES = {
  3005.2001: 'User account is locked',
  3005.2014: 'Email is not valid',
  3005.2015: 'New email is the same as the old email',
  3005.2016: 'Email is already in use',
  3005.2018: 'Loan verification data is invalid',
};

export const DISCLOSURE_NAMES_TO_SEND = {
  contactAcknowledgment: 'ElectronicCommunicationsPolicy',
  electronicAcknowledgment: 'EstatementPolicy',
  privacyAcknowledgment: 'PrivacyPolicy',
  termsAcknowledgment: 'TermsOfUse',
};

export const SSO_ACCOUNT_ERROR_DICTIONARY = {
  button: {
    loginLabel: 'Return to login',
    migrationLabel: 'Return to your mortgage provider',
  },
  heading: 'We couldn\'t complete your request',
  text: 'Please login by accessing your mortgage provider\'s main online platform.',
};

export const INTEREST_STATEMENT_BANNER_COPY = {
  heading: '1098 Interest Statement',
  text: 'Your 1098 interest statement will be mailed and available online by January 31st. Please keep in mind that we cannot reproduce and/or duplicate 1098 statements until February 16, 2023.',
};
