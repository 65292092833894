/* eslint-disable max-len */
import { LANGUAGE_ENGLISH, LANGUAGE_ENGLISH_MURABAHA } from '../../utils/constants';

const IDS = {
  // Document Upload
  DOCUPLOAD_STATUS_TITLE_FAILED: 'main.docUpload.status.title.failed',
  DOCUPLOAD_STATUS_TITLE_INPROCESS: 'main.docUpload.status.title.inProcess',
  DOCUPLOAD_STATUS_TITLE_PROCESSED: 'main.docUpload.status.title.processed',
  DOCUPLOAD_STATUS_TITLE_UPLOADED: 'main.docUpload.status.title.uploaded',
  // Marketing Cards
  MARKETING_CARDS_AMORTIZATION_TEXT_DESKTOP: 'main.marketing.cards.amortization.text.desktop',
  MARKETING_CARDS_AMORTIZATION_TEXT_MOBILE: 'main.marketing.cards.amortization.text.mobile',
  MARKETING_CARDS_AUTOPAY_TEXT: 'main.marketing.cards.autopay.text',
  MARKETING_CARDS_CALCULATOR_TEXT: 'main.marketing.cards.calculator.text',
  MARKETING_CARDS_DISASTER_TEXT: 'main.marketing.cards.disaster.text',
  MARKETING_CARDS_DOCUMENTS_TEXT: 'main.marketing.cards.documents.text',
  MARKETING_CARDS_ESCROW_TEXT: 'main.marketing.cards.escrow.text',
  MARKETING_CARDS_GLOSSARY_TEXT: 'main.marketing.cards.glossary.text',
  MARKETING_CARDS_HARDSHIP_TEXT: 'main.marketing.cards.hardship.text',
  MARKETING_CARDS_HELOCDRAW_TEXT: 'main.marketing.cards.helocdraw.text',
  MARKETING_CARDS_HELP_TEXT: 'main.marketing.cards.help.text',
  MARKETING_CARDS_LEARNINGCENTER_TEXT_DESKTOP: 'main.marketing.cards.learningcenter.text.desktop',
  MARKETING_CARDS_LEARNINGCENTER_TEXT_MOBILE: 'main.marketing.cards.learningcenter.text.mobile',
  // NavBar
  NAVBAR_LINK_LABEL_CALCULATORS: 'main.navbar.link.label.Calculators',
  NAVBAR_LINK_LABEL_DOCUMENTS: 'main.navbar.link.label.Documents',
  NAVBAR_LINK_LABEL_HELP: 'main.navbar.link.label.Help',
  NAVBAR_LINK_LABEL_LOGOUT: 'main.navbar.link.label.Logout',
  NAVBAR_LINK_LABEL_MORTGAGE: 'main.navbar.link.label.Mortgage',
  NAVBAR_LINK_LABEL_PAYMENTS: 'main.navbar.link.label.Payments',
  NAVBAR_LINK_LABEL_SETTINGS: 'main.navbar.link.label.Settings',
  // Tiles
  TILES_AMORTIZATION_LINKTEXT: 'main.tiles.amortization.linkText',
  TILES_AMORTIZATION_TEXT: 'main.tiles.amortization.text',
  TILES_AMORTIZATION_TITLE: 'main.tiles.amortization.title',
  TILES_AUTOPAY_LINKTEXT: 'main.tiles.autopay.linkText',
  TILES_AUTOPAY_TEXT: 'main.tiles.autopay.text',
  TILES_AUTOPAY_TITLE: 'main.tiles.autopay.title',
  TILES_DOCUMENTS_LINKTEXT: 'main.tiles.documents.linkText',
  TILES_DOCUMENTS_TEXT: 'main.tiles.documents.text',
  TILES_DOCUMENTS_TITLE: 'main.tiles.documents.title',
  TILES_LEARNINGCENTER_LINKTEXT: 'main.tiles.learningCenter.linkText',
  TILES_LEARNINGCENTER_TEXT: 'main.tiles.learningCenter.text',
  TILES_LEARNINGCENTER_TITLE: 'main.tiles.learningCenter.title',
  TILES_PAPERLESSBILLING_LINKTEXT: 'main.tiles.paperlessBilling.linkText',
  TILES_PAPERLESSBILLING_TEXT: 'main.tiles.paperlessBilling.text',
  TILES_PAPERLESSBILLING_TITLE: 'main.tiles.paperlessBilling.title',
  TILES_SETTINGS_LINKTEXT: 'main.tiles.settings.linkText',
  TILES_SETTINGS_TEXT: 'main.tiles.settings.text',
  TILES_SETTINGS_TITLE: 'main.tiles.settings.title',
};

const enMessages = {
  // Document Upload
  [IDS.DOCUPLOAD_STATUS_TITLE_FAILED]: 'Your loan document submission was rejected.',
  [IDS.DOCUPLOAD_STATUS_TITLE_INPROCESS]: 'Your loan document is being processed.',
  [IDS.DOCUPLOAD_STATUS_TITLE_PROCESSED]: 'Your loan document has been successfully processed.',
  [IDS.DOCUPLOAD_STATUS_TITLE_UPLOADED]: 'Your loan document is being processed.',
  // Marketing Cards
  [IDS.MARKETING_CARDS_AMORTIZATION_TEXT_DESKTOP]: 'View the breakdown of principal and interest you will pay over time.',
  [IDS.MARKETING_CARDS_AMORTIZATION_TEXT_MOBILE]: 'View the breakdown of principal & interest you will pay over time.',
  [IDS.MARKETING_CARDS_AUTOPAY_TEXT]: 'Set up automatic payments today. It’s simple, convenient, and efficient.',
  [IDS.MARKETING_CARDS_CALCULATOR_TEXT]: 'Estimate how much you can save on interest by making extra payments.',
  [IDS.MARKETING_CARDS_DISASTER_TEXT]: 'We’re here to help during the unexpected.',
  [IDS.MARKETING_CARDS_DOCUMENTS_TEXT]: 'Access forms, letters, and mortgage statements here.',
  [IDS.MARKETING_CARDS_ESCROW_TEXT]: 'Simplify homeownership! Ensure taxes & insurance are automatically paid on time.',
  [IDS.MARKETING_CARDS_GLOSSARY_TEXT]: 'Familiarize yourself with important terms for homeowners.',
  [IDS.MARKETING_CARDS_HARDSHIP_TEXT]: 'Learn what kind of help is available in times of hardship.',
  [IDS.MARKETING_CARDS_HELOCDRAW_TEXT]: 'Access funds from your home equity line of credit.',
  [IDS.MARKETING_CARDS_HELP_TEXT]: 'Explore resources and support options all in one place.',
  [IDS.MARKETING_CARDS_LEARNINGCENTER_TEXT_DESKTOP]: 'Visit the learning center. Browse through videos, FAQs, and our glossary to learn more.',
  [IDS.MARKETING_CARDS_LEARNINGCENTER_TEXT_MOBILE]: 'Browse through videos, faqs and our glossary.',
  // NavBar Dictionary
  [IDS.NAVBAR_LINK_LABEL_CALCULATORS]: 'Calculators',
  [IDS.NAVBAR_LINK_LABEL_DOCUMENTS]: 'Documents',
  [IDS.NAVBAR_LINK_LABEL_HELP]: 'Help',
  [IDS.NAVBAR_LINK_LABEL_LOGOUT]: 'Logout',
  [IDS.NAVBAR_LINK_LABEL_MORTGAGE]: 'Mortgage',
  [IDS.NAVBAR_LINK_LABEL_PAYMENTS]: 'Payments',
  [IDS.NAVBAR_LINK_LABEL_SETTINGS]: 'Settings',
  // Tiles Dictionary
  [IDS.TILES_AMORTIZATION_LINKTEXT]: 'Explore Calculators',
  [IDS.TILES_AMORTIZATION_TEXT]: 'Estimate how much you can save on interest by making extra payments.',
  [IDS.TILES_AMORTIZATION_TITLE]: 'Payoff your loan early',
  [IDS.TILES_AUTOPAY_LINKTEXT]: 'Enroll Today',
  [IDS.TILES_AUTOPAY_TEXT]: 'Set up automatic payments today. It’s simple, convenient, and efficient.',
  [IDS.TILES_AUTOPAY_TITLE]: 'Never miss a payment',
  [IDS.TILES_DOCUMENTS_LINKTEXT]: 'View Documents',
  [IDS.TILES_DOCUMENTS_TEXT]: 'Access forms, letters, and mortgage statements here.',
  [IDS.TILES_DOCUMENTS_TITLE]: 'Looking for a document?',
  [IDS.TILES_LEARNINGCENTER_LINKTEXT]: 'Learn More',
  [IDS.TILES_LEARNINGCENTER_TEXT]: 'Visit the learning center. Browse through videos, FAQs, and our glossary to learn more.',
  [IDS.TILES_LEARNINGCENTER_TITLE]: 'Understand homeownership',
  [IDS.TILES_PAPERLESSBILLING_LINKTEXT]: 'Enroll Today',
  [IDS.TILES_PAPERLESSBILLING_TEXT]: 'Access documents from almost anywhere. All your documents are safely kept in one place.',
  [IDS.TILES_PAPERLESSBILLING_TITLE]: 'Go paperless today',
  [IDS.TILES_SETTINGS_LINKTEXT]: 'View Settings',
  [IDS.TILES_SETTINGS_TEXT]: 'Update personal info, add loans, and adjust account preferences with ease.',
  [IDS.TILES_SETTINGS_TITLE]: 'Manage your settings',
};

const enMurabahaMessages = {
  ...enMessages,
  // Document Upload
  [IDS.DOCUPLOAD_STATUS_TITLE_FAILED]: 'Your account document submission was rejected.',
  [IDS.DOCUPLOAD_STATUS_TITLE_INPROCESS]: 'Your account document is being processed.',
  [IDS.DOCUPLOAD_STATUS_TITLE_PROCESSED]: 'Your account document has been successfully processed.',
  [IDS.DOCUPLOAD_STATUS_TITLE_UPLOADED]: 'Your account document is being processed.',
  // Marketing Cards
  [IDS.MARKETING_CARDS_AMORTIZATION_TEXT_DESKTOP]: 'View the breakdown of Murabaha Installment Payments you will pay over time.',
  [IDS.MARKETING_CARDS_AMORTIZATION_TEXT_MOBILE]: 'View the breakdown of Murabaha Installment Payments you will pay over time.',
  [IDS.MARKETING_CARDS_CALCULATOR_TEXT]: 'Estimate how much you can save on profit by making extra payments.',
  [IDS.MARKETING_CARDS_DOCUMENTS_TEXT]: 'Access forms, letters, and Murabaha Financing Transaction statements here.',
  // NavBar Dictionary
  [IDS.NAVBAR_LINK_LABEL_MORTGAGE]: 'Murabaha',
  // Tiles Dictionary
  [IDS.TILES_AMORTIZATION_TEXT]: 'Estimate how much you can save on profit by making extra payments.',
  [IDS.TILES_AMORTIZATION_TITLE]: 'Payoff your Murabaha Financing Transaction early',
  [IDS.TILES_DOCUMENTS_TEXT]: 'Access forms, letters, and Murabaha Financing Transaction statements here.',
  [IDS.TILES_SETTINGS_TEXT]: 'Update personal info, add accounts, and adjust account preferences with ease.',
};

export const INTL_IDS = IDS;

export default {
  [LANGUAGE_ENGLISH]: enMessages,
  [LANGUAGE_ENGLISH_MURABAHA]: enMurabahaMessages,
};
